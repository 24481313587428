import GooglePlacesSearch from '@/components/form/fields/googlePlacesSearch';
import FormTextField from '@/components/form/fields/textField';
import FormattedTextField from '@/components/formattedTextField';
import PageSection from '@/components/page/section';
import { useStepper } from '@/providers/stepper';
import { ArrowForwardIosRounded as ArrowForwardIosRoundedIcon } from '@mui/icons-material';
import { Autocomplete, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import handleGoNext from '../../helpers/formikValidateBeforeSubmit';
import { timezones } from '../dashboard/management/locations/form/timzeZones';

export const locationValidationSchema = yup.object().shape( {
	location: yup.object().shape( {
		address: yup.object().shape( {
			line1: yup
				.string()
				.required( 'Enter Address' )
				.max( 250, 'Address line is too long' ),
			line2: yup
				.string()
				.nullable().optional()
				.max( 64, 'Floor/Ste is too long' ),
			city: yup
				.string()
				.required( 'Enter City' )
				.max( 64, 'City is too long' ),
			state: yup
				.string()
				.required( 'Enter State' )
				.max( 64, 'State is too long' ),
			country: yup
				.string()
				.required( 'Enter Country' )
				.max( 64, 'Country is too long' ),
			postalCode: yup
				.string()
				.required( 'Enter Postal Code' )
				.max( 64, 'Postal Code is too long' ),
		} ),
	} ),
} );
export default function AddressFormDetails( { onBoarding }: { onBoarding?: boolean } ) {
	const formik = useFormikContext<any>();
	const { setActiveStep, activeStep } = useStepper();
	
	return (
		<Stack spacing={3}>
			<Stack spacing={1}>
				<Typography variant='h1' style={{ fontSize: '40px' }}>
					Where are you located?
				</Typography>
				<Typography style={{ fontSize: '18px' }}>
					Your company address can optionally be included on all your outgoing invoices and orders. <br/>
					Change or add more locations later.
				</Typography>
			</Stack>
			<Stack spacing={3} width={onBoarding ? { xs: '100%', md: 500 } : undefined}>
				<PageSection primary='Address'>
					<Stack>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={8}>
								<GooglePlacesSearch
									name='location.address.line1'
									textFieldProps={{
										label       : 'Address',
										autoComplete: 'address-line1',
										size        : 'medium',
									}}
									onSelect={( address: any ) => {
										formik.setFieldValue( 'location.address', address );
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<FormTextField
									fullWidth
									name='location.address.line2'
									label='Suite/Floor No.'
									autoComplete='address-line2'
									size='medium'
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='location.address.city'
									label='City'
									autoComplete='address-level2'
									size='medium'
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='location.address.state'
									label='State'
									autoComplete='address-level1'
									size='medium'
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='location.address.country'
									label='Country'
									autoComplete='country'
									size='medium'
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormTextField
									fullWidth
									name='location.address.postalCode'
									label='Zip/Postal Code'
									autoComplete='postal-code'
									size='medium'
								/>
							</Grid>
							<Grid item xs={12}>
								<FormTextField
									fullWidth
									name='location.name'
									label='Friendly Name'
									placeholder='Optional'
									size='medium'
								/>
							</Grid>
						
						</Grid>
					
					</Stack>
				</PageSection>
				<PageSection primary='Timezone'>
					<Autocomplete
						value={formik.values.location.timezone || ''}
						options={timezones}
						renderInput={( params ) => (
							<FormattedTextField
								{...params}
								fullWidth
								name='location.timezone'
								label='Timezone'
								size='medium'
							/>
						)}
						onChange={( e, value ) => formik.setFieldValue( 'location.timezone', value )}
					/>
				</PageSection>
			</Stack>
			{onBoarding && (
				<Box mt={5}>
					<Button
						color='primary'
						size='large'
						endIcon={<ArrowForwardIosRoundedIcon/>}
						onClick={async () => await handleGoNext( setActiveStep, formik, activeStep, 4 )}>
						Go Next
					</Button>
				</Box>
			)}
		</Stack>
	);
}
